@use '@angular/material' as mat;
@include mat.core();

// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "font-awesome/css/font-awesome.css";
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&amp;display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import '~material-icons/iconfont/material-icons.css';
//@import "../node_modules/angular-calendar/css/angular-calendar.css";
// @import '~quill/dist/quill.core.css';
// @import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

// @import "../node_modules/@fontsource/jost"; // Defaults to weight 400
// @import "../node_modules/@fontsource/jost/400.css"; // Specify weight
// @import "../node_modules/@fontsource/jost/400-italic.css"; // Specify weight and style


// Jost Font support CSS List Start 

// Weights: [100,200,300,400,500,600,700,800,900]
// Styles: [italic,normal]
// Subsets: [cyrillic,latin,latin-ext]

// Jost Font support CSS List End

 /* $bv-brand: (
  50: #e0f6f6,
  100: #b3e9e9,
  200: #80dadb,
  300: #4dcbcd,
  400: #26bfc2,
  500: #00b4b7,
  600: #00adb0,
  700: #00a4a7,
  800: #009c9f,
  900: var(--main-color),
  A100: #bcfdff,
  A200: #89fbff,
  A400: #56faff,
  A700: #3cf9ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text
  )
);

$dashboard-primary: mat.define-palette($bv-brand, 900);
$dashboard-accent: mat.define-palette($bv-brand, 900); 
$dashboard-warn: mat.define-palette($mat-red);
 
 $dashboard-theme: mat-light-theme(
  $dashboard-primary,
  $dashboard-accent,
  $dashboard-warn
); */
$my-app-primary: mat.define-palette(mat.$indigo-palette);
$my-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-app-warn: mat.define-palette(mat.$red-palette);

$my-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
      warn: $my-app-warn,
    ),
  )
);

@include mat.all-component-themes($my-app-theme);

/* $custom-typography: mat-typography-config(
  $font-family: "Montserrat, sans-serif",
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(16px, 24px, 500),
  $input: mat-typography-level(14px, 1.125, 400)
);
 */

//@include mat-core($custom-typography);
//@include angular-material-theme($dashboard-theme);
//@include mat.all-component-themes($dashboard-theme);
/* You can add global styles to this file, and also import other style files */

:root 
{
--main-color: #34b6b9; // or any global you wish to share with components 
--auth-color: #ffffff; // or any global you wish to share with auth components 
--secondary-color: #ee4c23;
--btn-color: #34b6b9;
--selection-color: #ee4c23;
}

// .mat-mdc-flat-button.mat-primary, .mat-mdc-raised-button.mat-primary, .mat-mdc-fab.mat-primary
.mat-mdc-flat-button.mat-primary, .mat-mdc-raised-button.mat-primary
{
  background-color: var(--btn-color) !important;
}

 

// <-------Globle Body and All Text- Font Family-(Montserrat) CSS Start-------->
* {
   font-family: "Jost", sans-serif !important;
   scrollbar-width: thin;
  //font-family: 'Jost' !important;
  // font-family: "Roboto", !important;
}
// <-------Globle Body and All Text- Font Family-(Montserrat) CSS End-------->

// <-------Font Awesome Icon CSS Start-------->
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome !important;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// <-------Font Awesome Icon CSS End-------->

// <-------Material Icons Icon CSS Start-------->
.material-icons {
  font-family: "Material Icons" !important;
}

.mat-icon.mat-primary
{
  color: var(--btn-color);
}


.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full
{
  background: var(--main-color);
}


// <-------Material Icons Icon CSS End-------->

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

*:focus{
  outline: none !important;
}

label {
  display: inline-block;
}

.form-group {
  margin-bottom: 1rem;
}

input.form-control{
  background-color: #FFF;
}

.form-control:focus {
  box-shadow: none !important;
}
// input{padding:0 5px 0px !important}
.primary-color {
  color: var(--main-color);
}
.secondary-color {
  color: var(--secondary-color);
}

.tertiary-color {
  color: #333333;
  font-weight: 500;
  font-size: 14px;
}
.primary-btn {
  background: var(--main-color);
  color: #ffffff;
}
.color-light {
  color: #ebebeb;
}
.active {
  cursor: pointer;
}
.mat-toolbar.mat-primary {
  background: #f7f7ff;
  margin-bottom: 25px;
  color: #000;
}
.mat-sidenav {
  // background: var(--main-color);
  border: #fff;
}
.mat-toolbar {
  height: 70px !important;
}
.cursor-pointer {
  cursor: pointer;
}
// .mat-drawer-container{background: #fff;}
.logo img {
  width: 130px;
  height: 130px;
}

/*login-screen*/
.login-screen {
  background-image: url("./assets/login-bg.jpg");
  height: 100vh;
}
.login-box {
  /* display: inline-flex; */
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 25%;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -30%);
  top: 30%;
}
.login-logo {
  margin-bottom: 50px;
  img {
   max-width: 100%;
  }
}
mat-form-field {
  width: 100%;
}

.d-grid {
  display: grid !important;
}

.login-box .color-light {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.login-box .active {
  font-size: 14px;
  font-weight: 600;
}

.login-screen input {
  padding: 25px 20px !important;
  border-radius: 10px;
}
.input-content {
  position: relative;
}
.input-content:before {
  position: absolute;
  content: "";
  background: #fff;
  height: 20px;
  width: 20px;
  top: -6px;
  transform: rotate(40deg);
  left: 25px;
}
.input-content-forgotpw {
  position: relative;
}
.input-content-forgotpw:before {
  position: absolute;
  content: "";
  background: #fff;
  height: 20px;
  width: 20px;
  top: -6px;
  -webkit-transform: rotate(40deg);
  transform: rotate(43deg);
  right: 46px;
}

.common-icon-round i {
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  margin: 15px 20px 15px 0px;
  background: var(--main-color);
  vertical-align: middle;
}

.clear-btn {
  color: #b0b3bc;
}
.mat-drawer-content {
  background: #f7f7ff;
}
.mat-list-item-content {
  justify-content: center;
  margin: 10px 0;
}

.mat-menu-panel {
  max-width: 340px !important;
  width: 340px !important;
}

/*custom inputs class*/
.custom-form input {
  // background: #f7f7ff;
  border: 0;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}
.custom-form label {
  width: 100%;
}

button.mat-mdc-raised-button, button.mat-mdc-raised-button {
  border-radius: 6px ;
  // border-radius: 25px ;
  // padding: 0 26px ;
  // font-weight: normal !important;
}

// .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
//   .mat-form-field-label,
// .mat-form-field-appearance-legacy.mat-form-field-can-float
//   .mat-input-server:focus
//   + .mat-form-field-label-wrapper
//   .mat-form-field-label {
//   color: var(--main-color);
//   font-weight: 500;
// }
.mat-chip.mat-standard-chip {
  background-color: var(--main-color) !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  mat-icon {
    font-size: 20px !important;
    margin-top: 6px !important;
  }
}
.mat-error {
  font-size: 10px;
  display: block;
}

// <<------- Material Table / Table CSS Start - Vivek Chauahan ------>>

.mat-mdc-table .mdc-data-table__header-row, .mat-mdc-table .mdc-data-table__row {
  height: 40px !important;
}

.table-scroll-height {
  // max-height: 440px;
  max-height: calc(100vh - 240px);
  overflow-y: auto;
}

.mat-table, .mat-mdc-table {
  border-spacing: 2px;
  width: 100%;
  th {
    background-color: #F8F8F8 !important;
    vertical-align: middle;
    border-collapse: collapse;
    border-color: #edf1f7;
    z-index: 2 !important;
    border-bottom: 1.5px solid #EAEDF1 !important;
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    padding: 0.5rem 0.5rem;
    .material-icons { 
      // font-size: 20px;
      //  height: 20px; 
      //  width: 20px;
      font-size: 25px;
      height: 25px;
      width: 25px;
        margin-top: -2px;
         margin-bottom: -6px;
        }
  }
  .popoverToolTipLineHeight {
    line-height: 38px !important;
  }
  td {
    border-bottom: 1px solid #edf1f7 !important;
    border-top: none !important;
    border-collapse: collapse;
    border-color: #edf1f7;
    background-color: #fff;
    padding: 0.5rem 0.5rem;
    color: #494949;
    font-size: 15px;
    vertical-align: middle;
    .material-icons { 
      // font-size: 20px;
      //  height: 20px; 
      //  width: 20px;
      font-size: 25px;
      height: 25px;
      width: 25px;
        margin-top: -2px;
         margin-bottom: -6px;
        }
  }
  th.action, td.action 
  { 
    // width: 92px; 
    width: 100px; 
    text-align: center;
  }
  box-shadow: none;
  
  .mat-sort-header-arrow {
    color: #000 !important;
  }
  // tr:nth-child(even) { td {background-color: #f7f9fc !important;}}
  // tr:nth-child(odd) { td {background-color: #fff !important;}}
}

.noList, .no-data {
  border: 1px solid #edf1f7 !important;
  border-top: none !important;
  background-color: #fff !important;
}

// <<------- Material Table / Table CSS End - Vivek Chauahan ------>>

ngx-float-button {
  a.fab-toggle {
    background-color: var(--secondary-color) !important;
    color: #ffffff !important;
    width: 50px !important;
    height: 50px !important;
    position: fixed !important;
    left: 95% !important;
    bottom: 5% !important;
    
    mat-icon {
      line-height: 50px;
    }
  }
  .content {
    line-height: 22px !important;
  }
}
ngx-daterangepicker-material {
  .md-drppicker.double {
    width: 650px;
    left: auto !important;
    right: 14px !important;
    top: 58px !important;
  }
  .md-drppicker td.active, .md-drppicker td.active:hover, .md-drppicker .ranges ul li button.active, .md-drppicker .btn, .md-drppicker .btn:focus, .md-drppicker .btn:hover {
    background-color: var(--main-color);
  }
  .md-drppicker .clear svg {
      top: -7px;
  }
}

.gig-loader {
  position: fixed;
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100vh;
  // z-index: 1001;
  z-index: 1060;
  img {
    position: absolute;
    top: 50%;
    left: calc(50% - 32px);
    transform: translate(-50%,-50%);
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  p {
    position: absolute;
    top: 63%;
    left: 53%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-weight: 500;
    color: #ffffff;
    font-size: 20px;;
  }
}
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.calendar, .color-header {
  .reddark {
    background: #f82e2d !important;
  }
  .redmed {
    background: #ff99a4 !important;
  }
  .redlight {
    background: #ffb6be !important;
  }
  .yellowdark {
    background: #fec858 !important;
  }
  .yellowmeddark {
    background: #fed88a !important;
  }
  .yellowmedlight {
    background: #fee3ab !important;
  }
  .yellowlight {
    background: #ffe9bc !important;
  }
  .greenlight {
    background: #c9efd7 !important;
  }
  .greenmed {
    background: #aee8c2 !important;
  }
  .greendark {
    background: #77d79a !important;
  }
}

.popup-calendar {
  ::ng-deep mat-dialog-container {
    padding: 0;
    border-radius: 6px;
  }
}

.cdk-overlay-connected-position-bounding-box {
  right: 80.4844px !important;
 // top: 50.5px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  background-color: #ffffff;
}

.header-name {
    color: var(--main-color);
    // font-size: 25px;
    // font-size: 20px;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.mat-snack-bar-container {
  background: #ffffff;
  color: #333333;
}

.selected-list .c-list .c-token { 
  color: #616161;
  font-weight: 500;
  border-radius: 4px !important;
  padding: 6px 32px 6px 15px !important;
  margin-bottom: 4px;
  border: 1px solid var(--main-color);
  color: gray;
}

.selected-list .c-list .c-token .c-remove {
  background: #616161;
  border-radius: 100%;
  width: 17px !important;
  height: 17px;
  padding: 2px;
  display: inline-block;
  svg {
    vertical-align: top;
    padding: 2px;
  }
}

.dropdown-item.active, .dropdown-item:active {
  background-color: transparent;
}

.selected-list .c-angle-down, .selected-list .c-angle-up {
  width: 15px !important;
  height: 15px !important;
  // svg {
  //   fill: #ffffff !important;
  //   background: #fc5e36 !important;
  //   border-radius: 100%;
  //   padding: 6px;
  // }
}

.clear-all {
display: none;
}

.toggle_menu {
  display: none;
}

.closeMenu {
  display: none !important;
}

@media screen and (max-width: 600px) {
  .toggle_menu {
    display: block !important;
    margin: 24px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
  }
  .closeMenu {
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  .chat-popup {
    width: 95% !important;
    max-width: max-content !important;
  }
}

@media screen and (max-width: 960px) {
  .table-responsiv {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media only screen and (max-width: 990px) {
  .chat-popup {
    width: 98% !important;
    max-width: none !important;
  }
}




.loadergif {
  text-align: center;
  // position: inherit;
  position: sticky;
  width: 100%;
  margin-top: -150px;
  img {
    width: 60px;
  }
}

.text_overflow_hidden{
    white-space: nowrap; 
    width: 350px;
    overflow: hidden;
    text-overflow: ellipsis; 
    display: inline-block;
    // border: 1px solid #000000;
}

.card-appointment {
  width: calc(100% - 16px);
  padding: 16px !important;
  small {
    color: #737070 !important;
  }
  .icon-appointment {
    padding: 10px;
    color: #fff;
    border-radius: 25px;
  }

  // .card-icon {
  //   background-color: #000000;
  // }
  
  .pending {
    background: #e62d5b;
  }

  .assigned {
    background: #d6cc39;
  }

  .accepted{
    background: #52af06;
  }

  .inprogress {
    background: #3b98e8;
  }

  .completed{
    background: #a9a9a9;
  }

  .expired {
    background: #ee4c23 !important;
  }

  .cancelled {
    background: purple;
  }

  .late.cancelled {
    background: #333;
  }

  .img-icon {
    width: 60px;
    border-radius: 50px;
    background-color: var(--main-color);
    color: #fff;
    margin-right: 9px;
  }

  .menu-icon {
    color: #4a4a4a !important;
    cursor: pointer;
  }
}
.app-date {
  color: var(--secondary-color) !important;
}

.card-appointment-popup {
  width: calc(100% - 16px);
  padding: 16px !important;
  small {
    color: #737070 !important;
  }
  p{
    font-size: 13px;
    margin-bottom: 0px !important;
  }
  
  hr {
    margin: 1rem 0 !important;
  }

  .icon-appointment {
    padding: 10px;
    color: #fff;
    border-radius: 25px;
  }

  // .card-icon {
  //   background-color: #000000;
  // }
  
  .pending {
    background: #e62d5b;
  }

  .assigned {
    background: #d6cc39;
  }

  .accepted{
    background: #52af06;
  }

  .inprogress {
    background: #3b98e8;
  }

  .completed{
    background: #a9a9a9;
  }

  .expired {
    background: #ee4c23 !important;
  }

  .cancelled {
    background: purple;
  }

  .late.cancelled {
    background: #333;
  }

  .img-icon {
    // width: 60px;
    border-radius: 50px;
    background-color: var(--main-color);
    color: #fff;
    margin-right: 15px;
    img {
      width: 52px;
    }
  }

  .menu-icon {
    color: #4a4a4a !important;
    cursor: pointer;
  }
}

// i {
//   max-width: 48PX !important;
// }

.img-data {
 
  .img-count {
    background: var(--main-color) !important;
    border-radius: 29px;
    color: #fff !important;
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
  }
}

.img-open {
  position: absolute;
  top: 55px;
  right: -10px;
  background: #ffffff;
  box-shadow: 0 0 50px #e4e4e4;
  z-index: 11;
  max-width: 560px;
  padding: 11px;
  height: auto;  
}  

.dis-img {
  // display: flex !important;
  div {
    display: inline-block;
  }
  .img-icon {
    width: 42px !important;
    margin-right: 1px !important;
  }
  
}
.hideIcon {
  display: none !important;
}

angular2-multiselect {
  position: relative;
  bottom: 24px;
  .cuppa-dropdown {
    border: 1px solid rgba(0, 0, 0, .5);
    border-radius: 4px;
  }
  .img-filter {
    width: 34px;
    margin-right: 8px;
    background: var(--main-color);
    border-radius: 28px;
  }
  .c-btn {
    border-radius: 0;
  }
}

.distanceImg {
  position: relative;
  small {
    display: inline-block;
    &:after {
      content: '';
      background: url('./assets/up-down-arrow.png') no-repeat;
      width: 20px;
      height: 20px;
      position: relative;
      top: 2px;
      right: -8px;
      display: block;
      float: right;
    }
  }
}

.commentWidth{
  width: 250px !important;
  max-width: 250px !important;
}

.notes-clr {
  color: var(--main-color);
}

ngx-float-button.filter-appointment a.fab-toggle {
  background-color: var(--main-color) !important;
}

.location-map {
  .map-markerdetails {
    .title {
      color: var(--main-color);
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
      div {
        color: #ff0000;
        font-size: 12px;
        display: inline-block;
      }
    }
    span i {
      color: #fff;
      padding: 10px;
      border-radius: 50%;
      margin: 0 10px 0px 0px;
    }
    .pending i {
      background: #e62d5b;
    }
    .assigned i {
      background: #d6cc39;
    }
    .inprogress i {
      background: #3b98e8;
    }
    .completed i {
      background: #a9a9a9;
    }
    .accepted i {
      background: #52af06;
    }
    .late.cancelled i {
      background: #ff0000;
    }
    .cancelled i {
      background: #800080;
    }
    .expired i {
      background: #ee4c23;
    }
  }
  .driver-name {
    label {
      font-size: 13px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .address-map {
    label {
      font-size: 13px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .locations-icons {
    position: relative;
    right: -10px;
    img {
      border-radius: 50px;
      background-color: var(--main-color);
      color:#fff;
      width: 45px !important;
      float: left;
    }
  }
  .nontechnician {
    .locations-icons {
      width: 100% !important;
      flex: 0 0 100%;
      max-width: 100%;
      img {
        height: 45px;
      }
    }
  }
  .locations-icons {
    img {
      height: 45px;
    }
  }
}

.highlight {
  background-color:#dcdc39; color: #333333;
}

.highBlack.highlight {
  background-color:#333333; color: #ffffff;
}

.globalchatcount {
  // position: absolute;
  // top: 0;
  // right: -10px;
  // background: #ff0000;
  // height: 25px;
  // width: 25px;
  // line-height: 25px;
  // text-align: center;
  // border-radius: 100%;


    position: absolute;
    top: -8px;
    right: 24px;
    background: #dc3545;
     line-height: 25px;
    text-align: center;
    border-radius: 100%;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    font-size: .85em;
    font-weight: 700;
    line-height: 1;
    color: white;
    padding: 0.35em 0.65em;
    border-radius: 35px;
    display: inline-block;


}

.hr-div{
  width: 98%;
  position: relative;
  bottom: 1px;
  left: 15px;
}
.hr-div1{
  width: 98%;
    position: relative;
    left: 13px;
}

.material-icons.close:before {
  content: "\e5cd";
  display: none;
}

.ng5-slider-span .actionRequired{
  color: red !important;
}

.ng5-slider-span .actionRequiredNew{
  color: green !important;
}

.clock-time {
  color: #ee4c23;
}

.leaflet-tooltip {
  left: 15px;
}

.ispreferred-radio {
  width: 100%;
  mat-radio-button {
    width: 33.33%;
    padding-left: 15px;
    position: relative;
    //left: 11%;
    bottom: 20px;
    display: inline-block !important;
  }
}

.pref-no{
  font-size: 14px;
}

.radio-err{
  position: relative;  
  bottom: 15px;
}
.country-code{
  .mat-select {
    position: relative;
    bottom: 10px;
  }
}

.dstim {
  position: relative;
  right: 14px;
  img {
    position: relative;
    left: 18px;
  }
}

.text-green {
  color: green;
  font-weight: 500;
}

.text-red {
  color: red;
  font-weight: 500;
}

.text-black {
  font-weight: 500;
}

.divider {
  border: 1px solid #e5dfdf;
    margin-top: 10px;
    margin-bottom: 10px;
}


.gi-card{ background-color: rgba(240,77,35,.2);
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 12px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.mat-mdc-dialog-surface, .mdc-dialog__surface {
  padding: 20px 24px 20px 24px;
}

.chat-popup {
  position: absolute !important;
  top: 20px;
  width: 66%;
  mat-dialog-container {
    padding: 0;
    .mat-mdc-dialog-surface,
      .mdc-dialog__surface {
        padding: 0;
      }
  }
}

button.btn-close {
  // font-size: 12px;
  // width: 24px;
  // height: 24px;
  // padding: 0px;
  // position: absolute;
  // top: 2px;
  // right: 2px;
  font-size: 22px;
  width: 35px;
  height: 35px;
  padding: 0px;
  position: absolute;
  top: 7px;
  right: 5px;
}

mat-icon.btn-close {
  font-size: 24px;
  padding: 0px;
  background-image: none !important;
  position: absolute;
  top: 2px;
  right: 2px;
}

.btn-close:focus {
  box-shadow: none;
  opacity: 0.5;
}

.popup-viewport-body {
  height: 65vh;
  // width: 200px;
  border: 0px solid black;
  overflow: auto;
}

// .mat-mdc-tab-header {
//   border-bottom: 1px solid rgba(0, 0, 0, .12);
// }

.mdc-tab.mat-mdc-focus-indicator {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
  height: 42px !important;
  min-width: auto !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  .mdc-tab__text-label{
    color: #676767;
    font-size: 16px;
    font-weight: 400 !important;
  }
}


// .mat-mdc-tab.mdc-tab {
//   height: 42px !important;
//   min-width: auto !important;
//   padding-right: 1rem !important;
//   padding-left: 1rem !important;
// }
.mat-mdc-tab-group {
  background: #fff;
  border-radius: 18px;
  // padding: 1.5rem;
  // min-height: calc(100vh - 102px);
}

.mdc-tab.mat-mdc-focus-indicator.mdc-tab--active  {
  height: 42px;
  color: #00B6B8!important;
  font-size: 16px !important;
  font-weight: 600 !important;
  background-color: #FFF;
  border-color: #dee2e6 #dee2e6 #fff;
  border-top: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: none;
  // background-color: #3f51b5;
  // border-radius: 8px 8px 0px 0px;
  .mdc-tab__text-label{
    color: #00B6B8;
    transition-delay: unset;
    transition: none;
    font-weight: 600 !important;
  }
  .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
    border-color: transparent;
    opacity: 1;
  }
}

// custom css use for mat tab button auto width as per text -> Vivek Chauhan
.mat-mdc-tab-group {
  .mat-mdc-tab-header {
      // border-bottom: 1px solid rgba(0, 0, 0, .12);
      margin-bottom: -1px;
    }
}
.mat-mdc-tab-body-wrapper {
  border-top: 1px solid rgba(0, 0, 0, .12);
  background-color: #fff;
}
.mat-mdc-tab-group.tab-button-auto-width {
  // .mat-mdc-tab-body {
  //     border: 1px solid rgba(0, 0, 0, .12);
  //   }
.mat-mdc-tab-header {
  // border-bottom: 1px solid rgba(0, 0, 0, .12);  
  .mat-mdc-tab-body.mat-mdc-tab-body-active {
      border: 1px solid rgba(0, 0, 0, .12);
    }
  .mat-mdc-tab-list {
  flex-grow: 0;
  }
}
}


.searchtec,.searchpat,.searchfilter
 {
  position: relative;
  input { 
    padding-right: 25px;
  }
  mat-icon {
    position: absolute;
    right: 16px !important;
    top: 8px !important;
    color: #495057;
  }
}


// loader css starts

.loading {
  position: fixed;
  top: 0; right: 0;
  bottom: 0; left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: .8em solid rgba(218, 219, 223, 1);
  border-left: .8em solid rgba(58, 166, 165, 1);
  animation: spin 1.1s infinite linear;
}
.loader, .loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@keyframes spin {
0% {
  transform: rotate(360deg);
}
100% {
  transform: rotate(0deg);
}
}

//loader css ends



.add-right-sticky-btn {
  position: fixed;
  left: 95%;
  bottom: 5%;
  background-color: var(--secondary-color);
  border-radius: 50px;
  color: #fff;
  border: 11px solid var(--secondary-color);
  cursor: pointer;
  font-size: 30px;
  max-width: 57px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

  .timepicker-overlay {
  z-index: 10000000 !important;
}

 .timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.filter-icon{
  color: #8f9bb3;
  bottom: 48px !important;
  width: 34px;
  height: 34px;
  border-radius: 50px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0px 1px 13px -5px rgba(0, 0, 0, 0.75);
  left: calc(100% - 42px);
  z-index: 32;
  position: fixed;
  .material-icons{
    font-size: 16px;
  }
}

.filter-icon.forChat {
    color: #8f9bb3;
    // bottom: 8px !important;
    bottom: 55px !important;
    width: 34px;
    height: 34px;
    border-radius: 50px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0px 1px 13px -5px rgba(0, 0, 0, 0.75);
    left: calc(100% - 42px);
    z-index: 32;
    position: fixed;
    .material-icons{
      font-size: 16px;
    }
}
.form-label {
  position: absolute;
  // margin-top: -15px !important;
  // margin-left: 0.5rem!important;
  margin-top: -36px !important;
  margin-left: 12px !important;

  span{
    background: #fff !important;
   // color: #6c757d;
  //  color:  var(--main-color);
  color: rgb(14 14 14 / 37%);
    padding-right: 0.25rem!important;
    padding-left: 0.25rem!important;
    // font-size: .875em;
    font-size: 13px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
    position: relative;
    z-index: 5;
    line-height: normal;
    pointer-events: all;
  }
}

// textarea {
//   height: 40px;
// }

.dialog-header{
  color: var(--main-color);
  &:after {
      content: '';
      display: block;
      border-bottom: 2px solid var(--secondary-color);
      width: 30px;
      margin: 8px 0 20px;
  }
}
.form-card {
  background-color: #FFF;
  padding: 1.5rem;
  border: .0625rem solid #e4e9f2;
  border-radius: .25rem;
}

// <<------- Material Form CSS Start - Vivek Chauahan ------>>

.help-block {
  color: #f44336;
  font-size: 12px;
  display: block;
  font-weight: normal;
   font-weight: 600;
}

.mat-mdc-form-field-infix {
  min-height: 38px;
  line-height: 38px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 0;  
}

.mat-mdc-text-field-wrapper {
  background-color: #fff;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 38px;
  height: 38px;
  padding: 0px;
}

.mat-mdc-button-touch-target {
  height: 38px !important;
  width: 38px !important;
}

.mat-mdc-radio-button .mdc-form-field label, .mdc-form-field label {
  margin-bottom: 0px !important;
}

// <<------- Material Form CSS End - Vivek Chauahan ------>>


// <<------- angular2-multiselect CSS Start - Vivek Chauahan ------>>

.customClassAng2Multiselect {
  .dropdown-list.animated.fadeIn.tagToBody{
    position: fixed !important;
    .list-area {      
    font-size: 14px;
    }
    .list-area{
    .lazyContainer{
      padding-top: 10px;
      li {
        padding: 0px 10px 10px;
        font-size: 14px;
      }
  }
  }
  }
}

// <<------- angular2-multiselect CSS End - Vivek Chauahan ------>>

// <<------- mat-chip-list CSS Start - Vivek Chauahan ------>>

mat-chip-list {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  min-height: 45px;
  min-width: 100%;
  display: block;
  padding: 6px 6px 1px 6px;
  .mat-mdc-chip {
    border: 1px solid #34b6b9;
    margin-bottom: 5px;
    margin-right: 4px;
    color: #212529;
    background-color: transparent !important;
    border-radius: 4px;
    .mat-mdc-chip-focus-overlay { 
      display: none !important;
    }
    .mat-mdc-chip-action {
      padding: 0px 5px;
    }
    .mat-mdc-chip-action-label{
      // line-height: 30px;
      color: gray !important;
      font-size: 14px;
      background-color: transparent;
      padding-top: 1px;
      line-height: 18px;
      .material-icons {
        margin-left: 4px;
        cursor: pointer;
        height: auto;
        width: auto;
        font-size: 20px;
        margin-bottom: -5px;
      }
    }
  }
}

// <<------- mat-chip-list CSS End - Vivek Chauahan ------>>
 
//----------------------------------------------------------------//

.prof-view-card{
 width: 98%;
.profile-widget {
  background-color: #ffffff;
  border: 1px solid #ededed;
  margin-bottom: 30px;
  padding: 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}
.profile-widget .profile-img {
  cursor: pointer;
  height: 80px;
  margin: 0 auto;
  position: relative;
  width: 80px;
}
.profile-widget .dropdown.profile-action {
  text-align: right;
  position: absolute;
  top: 10px;
  right: 5px;
}
.profile-widget .dropdown.profile-action .action-icon {
  color: #777;
  font-size: 18px;
  display: inline-block;
}
.dropdown-toggle {
  white-space: nowrap;
}
.profile-widget .user-name {
  color: #333333;
}
.text-muted {
  color: #8e8e8e !important;
}
.dropdown-toggle::after {
  content: none;
}

.card {
  .card-img-top {
    border-radius: 50%;
    height: 64px;
    width: 64px;
  }
  .dropdown.profile-action{
    position: absolute;
    right: 8px;
    top: 12px;
    display: inline-block;
    .action-icon {
      .material-icons {color: #212529;}
    }
    .dropdown-item{
      display: flex;
      color: #212529;
      font-size: 14px;
      mat-icon {
      font-size: 16px;
      line-height: 30px;
    }
    .dropdown-item:hover, .dropdown-item:focus, .dropdown-item:active {
      color: #212529;
    }
  }
  }
}
.card:hover {
  .dropdown.profile-action{
    display: block;
  }
}
}

.inactiveCard{
  border-color: #ff6d7e;
  background-color: #fef3f5;
}

.eligibleSkillAction{
  // border-color: #ff6d7e;
  background-color: #F2F2F2;
}

.actionOnSkillsExpired {
  border-color: #ff6d7e;
}

//----------------------------------------------------------------//

// <<------- Bootstrap 5 Model/Popup CSS Start - Vivek Chauahan ------>>

.modal-backdrop{
  z-index: 30 !important;
}

.modal.bs-model-popup{
.modal-content{
  overflow: hidden !important;
  width: auto;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  .btn-close {
    padding: 0.5rem 0.5rem;
    margin: 0.5rem 0.25rem -0.5rem auto !important;
}
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
}

.modal.bs-model-popup .modal-body {
  overflow: hidden;
}

.modal.bs-model-popup .modal-dialog-scrollable .modal-body {
  overflow: auto;
}

// .modal.bs-model-popup.modal-fullscreen {
//   margin: 0px !important;
//     padding: 0px !important;
//    .modal-dialog {
//     display: inline-block !important;
//   width: 100vw !important;
//   max-width: 100vw !important;
//   height: 100vh !important;
//   max-height: 100vh !important;
//   margin: 0 !important;
//   .modal-content{
//     height: 100vh !important;
//   max-height: 100vh !important;
//   }
//    }
// }

.modal.bs-model-popup.come-from-modal.left .modal-dialog,
.modal.bs-model-popup.come-from-modal.right .modal-dialog {
    position: fixed!important;
    right: -80vw!important;
    top: 0!important;
    margin: auto!important;
    height: 100vh!important;
    width: 80vw!important;
    -webkit-transform: translate3d(0%, 0, 0)!important;
    -ms-transform: translate3d(0%, 0, 0)!important;
    -o-transform: translate3d(0%, 0, 0)!important;
    transform: translate3d(0%, 0, 0)!important;
}

.modal.bs-model-popup.come-from-modal.left .modal-content,
.modal.bs-model-popup.come-from-modal.right .modal-content {
    height: 100vh!important;
    overflow-y: auto!important;
    border-radius: 0px!important;
}
.modal.bs-model-popup.come-from-modal.right.fade .modal-dialog {
    // right: -320px;
    right: 0vw!important;
    -webkit-transition: right 3s ease-out!important;
    -moz-transition: right 3s ease-out!important;
    -o-transition: right 3s ease-out!important;
    transition: right 3s ease-out!important;
}

.modal.bs-model-popup.come-from-modal.right.fade.in .modal-dialog {
    right: 0!important;
}

// <<------- Bootstrap 5 Model/Popup CSS End - Vivek Chauahan ------>>

.maxLogoWidth {
  max-width: 100%;
  max-height: 100px;
}


/*************************** setting header text***************************************************/


.accordian-description {
  margin: 10px 0;
  text-transform: capitalize;
  .orange-title {
    font-size: 15px;
    color: var(--secondary-color);
    margin-bottom: 10px;
  }
  // .orange-title:after {
  //   content: ':';
  //   width: 15px;
  //   display: inline-block;
  //   margin: 0 0 0 5px;
  //   font-size: 30px;
  //   position: relative;
  //   top: 2px;
  // }
  .days {
    margin: 25px 0 10px;
    mat-checkbox {
      width: 110px;
  display: inline-block;
      text-transform: capitalize;
    }
    .days .day-name {width: 110px !important;}
  }

  .help-block{
    text-transform: none;
  }

}


/**************************************************************************************************/


// Bootstrap dropdown-toggle no-caret CSS Start

.dropdown-toggle.dropdown-toggle-no-caret {
  border: none;
  padding: 4px 8px;
}

.dropdown-toggle.dropdown-toggle-no-caret:active, :not(.btn-check)+.dropdown-toggle.dropdown-toggle-no-caret.btn:active, .dropdown-toggle.dropdown-toggle-no-caret.btn.show, .dropdown-toggle.dropdown-toggle-no-caret.btn:hover, .dropdown-toggle.dropdown-toggle-no-caret.btn:focus, .dropdown-toggle.dropdown-toggle-no-caret.btn:visited {
  border: none;
}


.dropdown-toggle-no-caret::after {
  display:none;
  content: none;
}

.dropdown-toggle.dropdown-toggle-no-caret::after {
  content: none;
}

// Bootstrap dropdown-toggle no-caret CSS End

// Bootstrap Nav Tabs CSS Start

.nav-tabs .nav-link {
  color: #676767;
  font-size: 16px;
  font-weight: 400;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #00B6B8;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

// Bootstrap Nav Tabs CSS End


.StickyPosition.position-relative {
  
    position: relative;
    overflow-x: hidden;
    height: calc(100vh - 185px);
  
  .position-sticky
  {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #fff;
  }
}

.clockIn-Out-btn{
  background: #f44336;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 5px;
  box-shadow: var(--mdc-protected-button-container-elevation, 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12));
}

mat-card {
  border-left: 3px solid #34b6b9 !important ;
  //border-right: 3px solid #34b6b9 !important ;
}



.requested-job-card-list
{
 small
 {
  color: #737070!important;
 }

 .job-mat-card
 {
   padding: 15px;
 }

 table {
   width: 100%;
 }

 table thead tr th{
   color: #737070!important;
  font-weight: normal;
 }
 table tbody tr th
 {
   font-weight: 500;
   font-size: 14px;
 }

}


/*shift details card css starts*/

.searchfilter {
  border: none;
 }

 .select-shift-div
 {
  small
  {
   color: #737070!important;
  }

  .select-shift-card-height
  {
    overflow: auto;
    height: 70vh;
  }

  .shift-select-card
  {
    padding: 15px;
  }

  table {
    width: 100%;
  }

  table thead tr th{
    color: #737070!important;
  font-weight: 100;
  }
  table tbody tr th
  {
    font-weight: 500;
    font-size: 14px;
  }

 }
/*shift details card css ends*/


mat-icon{
  cursor: pointer !important;
}

.mini-header {
  font-size: 15px;
  margin-bottom: 20px;
  color: var(--main-color);
}

.customPopUpScrollBody {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.nowrapTxt {
  text-wrap: nowrap;
}

.mat-select-search-inner {
  background-color: #ffffff !important;
}


.simple-table
{
  // min-height: calc(100vh - 400px)!important;
  // max-height: calc(100vh - 400px)!important;

   height: calc(100vh - 550px)!important;
   overflow-y: auto;

  thead td {
  font-size: 16px;
  color: #000;
}

  tbody td {
    font-size: 15px;
    color: #676767;
    border-bottom: 1px solid #c9c8c8;
    padding: 5px;
}

 
table {
  width: 100%; /* Ensure the table takes up the full width of its container */
  border-collapse: collapse; /* Remove spacing between table cells */
}

thead {
  // background-color: #f2f2f2; /* Background color for the header */
  position: sticky;
  top: 0; /* Stick the header to the top of the container */
}

  thead tr {
  background-color: #F8F8F8;
  border: 0;
 
}

}

//*************************************** mat-option css ****************************************

// use class="mt-op"  to apply even odd background on <mat-option> selector
  
 .mt-op.mat-mdc-option:nth-child(even) {
  background-color: #f7f5f5; 
}

 
 .mt-op.mat-mdc-option:nth-child(odd) {
  background-color: #ffffff;  
}

//  .mt-op.mat-mdc-option:hover
.mt-op.mat-mdc-option:hover
{
  color: var(--main-color) !important;
 // background: none !important;
  
}

  .mdc-list-item--selected .mdc-list-item__primary-text
{
  color: var(--main-color) !important;
}
 
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after
{
  color: var(--main-color) !important;
}

//*****************************************************************************************

.custom-popover {
  --bs-popover-max-width: 200px;
  --bs-popover-border-color: var(--bd-violet-bg);
  --bs-popover-header-bg: var(--bd-violet-bg);
  --bs-popover-header-color: var(--bs-white);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: .5rem;
}

.spinner.spinner-sm .mat-mdc-progress-spinner {
  width: 30px !important;
  margin: auto !important;
}

.pasteJobTxt{
  background: #e5e0e0;
    border-radius: 7px;
}

.mb-55 {
  margin-bottom: 55px !important;
}


.popup-pos
{
  position: absolute !important;
  top: 10px !important;
}
.notesIc {
  transform: rotate(-90deg);margin-left: 5px;margin-top: -8px;color: #000;
 }

 .job-details
 {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.poupup-content {
  height: 60vh !important;
  overflow: auto;
}

// Common button css start

.headerNoteTitleBtn {
  font-size: 14px; 
  font-weight: normal;
  .notebtnContainer {
    .notebtnIc {
      max-width: 26px; 
      height: auto;
    }
    .noteBtnTxtInactive {
      color: #333;
    }
    .noteBtnTxtActive{      
      color: #34b6b9;
    }
  }
}

// Common Note button css End


// back-arrow
 .arrow_back
 {
  background: var(--main-color);
    color: #fff;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 50px;
    width: 35px;
    height: 35px;
    cursor: pointer;
 }

 .center_50_px_spinner {
  width: 50px !important;
  height: 50px !important;
  padding: 0;
}

.popoverCls {
  position: relative;
  z-index: 6;
}

.user-image{
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-right: 10px;
}

.staff-ellipsis {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100vw - 94vw);
}



// ---<<<<--- Dropdown Mat Single select add Reset selected value CSS Start ----->>>>>---

.resetSelectedOption.mat-mdc-option.mdc-list-item {
  color: red !important;
  opacity: .75 !important;
}

.resetSelectedOption > .mat-pseudo-checkbox {
  display: none !important;
}

.resetSelectedOption.mdc-list-item--selected > .mdc-list-item__primary-text {
  color: red !important;
  opacity: .75 !important;
}

// ---<<<<--- Dropdown Mat Single select add Reset selected value option CSS End ----->>>>>---


/* outlined textbox*/

.outline-textbox {
  .positionAbsLabel {
    position: absolute;
    font-size: 12px;
    margin-top: -8px;
    margin-left: 12px;
    color: rgba(0, 0, 0, 0.3);
    background-color: #fff;
    padding: 0px 4px;
    letter-spacing: 0.02125em;
    font-weight: 600;   
    z-index: 10; 
  }
  
  .timeInput {
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.5);
  }
}


/*
* Mat tooltip
*/
.mat-mdc-tooltip {
   font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.8px;
}


.label-value {
  display: flex;
  flex-direction: column;
}

ul.list{
  list-style-type: none;  
  padding: 0;            
  margin: 0; 
  overflow-y: auto;
 
  & li{
      padding: 5px;
      border-radius: 5px;
      font-size: 14px;
      color: #696969;
      margin-bottom: 5px;
      &.active{
        background: #d6eced;
        border: 1px solid var(--btn-color);
      }
      &:hover{
        background: #f4f4f4;
      }
  }

}

.noRecordFound{
  padding: 5px;
    text-align: center;
    font-size: 14px;
    color: #fe5454;
    background: #fccfcf;
    font-weight: 600;
    border-radius: 5px;
}



.mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above 
{
  background: #fff;
}


/*
 gig tooltip css
*/
.gig-tooltip{
  background-color: #232F34;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
}


.mdc-menu-surface.mat-mdc-autocomplete-panel
{
  max-height: calc(50vh - 125px) !important;
}

 ::ng-deep .mdc-menu-surface {
  max-height: calc(50vh - 125px) !important;
 }

.mdc-menu-surface.mat-mdc-select-panel{
  max-height: calc(50vh - 125px) !important;
}

// .mat-mdc-option.mdc-list-item:last-child {
//   margin-bottom: 35% !important;
// }


.mat-mdc-form-field-error {
   font-size: 12px;
  font-weight: 600;
}

.required-field-star{
  color: red;
}